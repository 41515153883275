var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-0 ma-0"},[_c('tr',[_c('td',{},[_c('div',[_vm._v(_vm._s(_vm.item.nombre))])]),_c('td',{},[_c('div',[_vm._v(_vm._s(_vm.item.comprados))])]),_c('td',{},[_c('div',[_vm._v(_vm._s(_vm.item.usados))])]),_c('td',{},[_c('div',[_vm._v(_vm._s(_vm.item.timbres_disponibles))])]),_c('td',{},[_c('div',[_vm._v(_vm._s(_vm.item.ultimo))])]),_c('td',[_c('div',{staticClass:"tblOpciones"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.detalle()}}},on),[_c('v-icon',{staticClass:"iconoDelgadoBoton",domProps:{"textContent":_vm._s('$visibility_outline')}})],1)]}}])},[_c('span',[_vm._v("Ver")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){_vm.isOpen ? _vm.isOpen = false:_vm.isOpen = true}}},on),[_c('v-icon',{staticClass:"tamIconoBoton iconoDelgadoBoton"},[_vm._v(_vm._s(_vm.isOpen ? 'keyboard_arrow_up':'keyboard_arrow_down'))])],1)]}}])},[_c('span',[_vm._v("Desplegar")])])],1)])]),(_vm.isOpen)?[_vm._m(0),_vm._l((_vm.facturacion),function(datos){return _c('tr',{key:datos.id,staticClass:"td-short"},[_c('td',[_c('div',[_vm._v(" "+_vm._s(datos.razon_social)+" ")])]),_c('td',[_c('div',[_vm._v(" "+_vm._s(datos.rfc)+" ")])]),_c('td',[_c('div',[_vm._v(" "+_vm._s(datos.regimen)+" ")])]),_c('td',[_c('div',[_vm._v(" "+_vm._s(datos.uso_cfdi)+" ")])]),_c('td',{staticStyle:{"padding-left":"8px","padding-right":"8px"}},[_c('div',{staticClass:"tblOpciones"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.abrirModal(datos)}}},on),[_c('v-icon',{staticClass:"tamIconoBoton invertirColorBotones"},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"icon":"","fab":"","small":""},on:{"click":function($event){return _vm.pagar(datos)}}},on),[_c('v-icon',{staticClass:"tamIconoBoton outlined_v_icon",attrs:{"color":"#96999A"}},[_vm._v("shopping_cart")])],1)]}}],null,true)},[_c('span',[_vm._v("Comprar")])])],1)])])})]:_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"td-titles"},[_c('td',[_c('div',[_vm._v(" Razón social ")])]),_c('td',[_c('div',[_vm._v(" RFC ")])]),_c('td',[_c('div',[_vm._v(" Régimen fiscal ")])]),_c('td',[_c('div',[_vm._v(" Uso CFDI ")])]),_c('td',[_c('div',{staticClass:"tblOpciones"},[_vm._v(" Opciones ")])])])}]

export { render, staticRenderFns }